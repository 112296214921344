import React, { useState, useEffect } from 'react';
import DynamicTabs from './DynamicTabs';
import { ApiCall } from '../ApiCall';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import FormattedDate from '../DataManipulation/FormatDate';
import FormattedCurrency from '../DataManipulation/FormatCurrency';

import '../../styles/Orders.scss';

function Orders() {
  const navigate = useNavigate();
  const { Transid } = useParams()
  const [Order, setOrder] = useState([]);
  const [BillTo, setBillTo] = useState([]);
  const [ShipTo, setShipTo] = useState([]);
  const [AP, setAP] = useState([])
  const [Purchaser, setPurchaser] = useState([])
  const [SalesLines, setSalesLines] = useState([])
  const [Payments, setPayments] = useState([])
  const [Tracking, setTracking] = useState([])

  const customerProfile = useSelector(state => state?.customer);//TODO: get customer profile from redux to associate with various orders. i.e. customers may have multiple carts, invoices, etc.

  useEffect(() => {
    const fetchOrders = async () => {
      var data = {
        'TransID': Transid,
        'RowGuid': 'NULL'
      };
      const response = await ApiCall('tq_Order_Receipt', data, 'Tcp');
      setOrder(response?.data?.Table);
      setBillTo(response?.data?.Table1);
      setShipTo(response?.data?.Table2);
      setAP(response?.data?.Table3);
      setPurchaser(response?.data?.Table4);
      setSalesLines(response?.data?.Table5);
      setPayments(response?.data?.Table6);
      setTracking(response?.data?.Table7);
    };
    fetchOrders();
  }, [Transid]);

  const handleBackToOrders = () => {
    navigate(`/customer/orders`);
  };

  return (
    <>
      <DynamicTabs tabOverride="orders" />
      <div className="container">
        <div className='row'>
          <div className='col-md-12' >
            <div onClick={handleBackToOrders}>
              <button className='universal-btn-inverted me-2' style={{ padding: '5px 10px' }}>{"<"}</button>
              <span style={{ color: '#F5863C' }}>Back To Orders</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Billing For Customer # {BillTo[0]?.CustID}</h5>
                <div>{BillTo[0]?.CustName}</div>
                <div>{BillTo[0]?.Address1}</div>
                <div>{BillTo[0]?.Address2}</div>
                <div>{BillTo[0]?.City}, {BillTo[0]?.State} {BillTo[0]?.PostalCode}</div>
                <div>{BillTo[0]?.Country}</div>
                <div>Phone: {BillTo[0]?.Phone} / Fax: {BillTo[0]?.Fax}</div>
                <div>Email: {AP[0]?.ApEmail}</div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Ship to</h5>
                <div>{ShipTo[0]?.Attn}</div>
                <div>{ShipTo[0]?.ShiptoName}</div>
                <div>{ShipTo[0]?.Address1}</div>
                <div>{ShipTo[0]?.Address2}</div>
                <div>{ShipTo[0]?.City}, {ShipTo[0]?.State} {ShipTo[0]?.PostalCode}</div>
                <div>{ShipTo[0]?.Country}</div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Order # {Order[0]?.OrderNum}</h5>
                <div>Purchaser: {Purchaser[0]?.PurchaserName}</div>
                <div>Date: {Order[0]?.OrderDate && <FormattedDate date={Order[0]?.OrderDate} dateFormat='MM/dd/yyyy' />}</div>
                <div>RepID: {Order[0]?.SalesRep}</div>
                <div>Tax ID: {Order[0]?.TaxID}</div>
                <div>Shipped: {Order[0]?.ShipVia}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br /><br /><br />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <table className="table">
              <thead>
                <tr>
                  <th>Qty</th>
                  <th>Description</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {SalesLines.map((SalesLine) => (
                  <tr key={SalesLine.EntryNum}>
                    <td>{SalesLine.QuantityShipped}</td>
                    <td>
                      {SalesLine.Description}<br />
                      {SalesLine.AdditionalDescription}
                    </td>
                    <td>{SalesLine.UnitPrice}</td>
                    <td>{SalesLine.Total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br /><br /><br />

      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Payment & Shipping Details</h5>
                {Payments.map((Payment) => (
                  <div>
                    <FormattedCurrency value={Payment?.AppliedAmount} />
                    {' of '}
                    <FormattedCurrency value={Payment?.PaymentAmount} />
                    {' on '}
                    <FormattedDate date={Payment?.PaymentDate} dateFormat='MM/dd/yyyy' />
                    {'. CC Auth # '}
                    {Payment?.CcAuth}
                  </div>
                ))}
                <br />
                <b>Tracking:</b>
                {Tracking.map((track) => (
                  <div>{track.TrackingNum}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body d-flex flex-column">
                <div>Subtotal: <FormattedCurrency value={Order[0]?.SubTotal} /></div>
                <div>Shipping: <FormattedCurrency value={Order[0]?.Shipping} /></div>
                <div>Sales Tax: <FormattedCurrency value={Order[0]?.SalesTax} /></div>
                <div>Payments: <FormattedCurrency value={Order[0]?.Payments} /></div>
                <div>Balance: <FormattedCurrency value={Order[0]?.Balance} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
